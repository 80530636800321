import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from "gatsby";

import Layout from "../components/layout";
import { About, Facilities, History, Gallery, RelatedProperties, Review } from "../components/sections";
import { SecondaryNav } from "../components/composites";
import { Hero } from "../components/primitives";
import { getAssetURL } from "../utils";

import './winery.scss'


const WineryTemplate = ({data, location}) => {
    const {
        directus: {
            reviews,
            wineries,
        }
    } = data;

    const winery = wineries[0];
    const wineryId = winery?.id ?? '';
    const about = winery?.about ?? '';
    const name = winery?.name ?? '';
    const featuredImage = winery?.featured_image ?? '';
    const summary = winery?.summary ?? '';
    const logo = getAssetURL(winery?.logo?.id ?? '');
    const website = winery?.website ?? '';
    const wineryHistory = winery?.history ?? '';
    const region = winery?.region ?? '';
    const country = winery?.country?.name ?? '';
    const additionalImages = winery?.additional_images ?? null;
    const relatedWinery = winery?.related_winery ?? null;
    const metaTitle = (`${name} | ${summary}`);
    const cleanTitle = metaTitle.length > 70 ? metaTitle.substring(0,70) : metaTitle;

    return(
        <Layout
            className='winery-template'
            location={location}
            title={cleanTitle}
            description={about ?? wineryHistory}
            currentPage='winery'
            image={winery?.featured_image?.filename_disk ?? null}
        >
            <Hero
                title={name}
                bgImage={featuredImage}
                subtitle={summary}
                overlayColor='rgba(0,0,0,.40)'
                Tag='h1'
            />
            <SecondaryNav reviews={reviews}/>
            <About about={about} logo={logo} name={name} website={website} location={location}/>
            <Facilities region={region} country={country} winery={winery}/>
            {additionalImages.length > 0 && <Gallery images={additionalImages} />}
            <History wineryHistory={wineryHistory}/>
            <Review wineryId={wineryId} reviews={reviews}/>
            {relatedWinery && <RelatedProperties winery={relatedWinery}/>}
        </Layout>
    )
}

WineryTemplate.propTypes = {
    data: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
}

WineryTemplate.defaultProps = {

}

export const WineryQuery = graphql`
  query($slug: String!) {
    directus {
      wineries(
        filter: {slug: {_eq: $slug} }
      ) {
        id
        about
        name
        history
        summary
        website
        region
        instagram
        languages_spoken
        grape_varieties
        wines_produced
        accommodation
        accommodation_website
        cellar_door
        cellar_door_website
        events
        events_website
        other_experiences
        restaurant
        restaurant_website
        tastings
        tastings_website
        tours
        tours_website
        latitude
        longitude
        google_rating
        country {
          name
          slug
        }
        logo {
            id
        }
        featured_image {
            filename_disk 
        }
        additional_images {
            directus_files_id {
              filename_disk
              }
         }
        thursday_open_summer
      thursday_open
      thursday_opening_hours
      thursday_summer_hours
      friday_open
      friday_open_summer
      friday_opening_hours
      friday_summer_hours
      monday_open
      monday_open_summer
      monday_opening_hours
      monday_summer_hours
      has_summer_hours
      saturday_open_summer
      saturday_opening_hours
      saturday_summer_hours
      saturday_open
      sunday_open
      sunday_open_summer
      sunday_opening_hours
      sunday_summer_hours
      tuesday_open
      tuesday_open_summer
      tuesday_opening_hours
      tuesday_summer_hours
      wednesday_open
      wednesday_open_summer
      wednesday_opening_hours
      wednesday_summer_hours
      related_winery {
        slug
        name
         featured_image {
            filename_disk 
        }
        region
        country {
          name
          slug
        }
      }
      }
      reviews(
        filter: {winery: {slug: {_eq: $slug}}}
        ) {
          comment
          name
          rating
          title
          winery {
            slug
          }
       }
    }
  }
`

export default WineryTemplate